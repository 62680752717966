import readXlsxFile from "read-excel-file";
import { mapActions } from "vuex";
import { $EventBus } from "@/main";
import moment from 'moment';
import { validateFileSize, validExtension } from '@/helpers/validationList';
import {
  isNotEmpty,
  hasExtension,
  hasTitles,
} from "@/helpers/validationList.js";

export default {
  name: "ImportMassiveDialog",
  data() {
    return {
      mode: "FORM",
      selectedFile: null,
      importMassiveForm: {
        selectedFile: null,
      },
      fileRule: [
        (v) => !!v || 'Campo requerido',
        (v) =>
          validExtension(v, [
            '.xslx',
            '.xlsx',

          ]) || 'La extensión del archivo no es válida',
        (v) =>
          validateFileSize(v, 10) ||
          '"El archivo es demasiado grande. Máximo 10 MB',
      ],
      dialog: false,
      listError: [],
      valid: false,
      loading: false,
      loadingSave: false,
      filelist: [],
      savedAssets: [],
      headers: [
        { text: "Nombre",sortable:false, value: "name" },
        { text: "Número de serie",sortable:false, value: "serial_number" },
        { text: "Tipo de activo",sortable:false, value: "asset_type" },
        { text: "Área",sortable:false, value: "area" },

        { text: "Número de activo",sortable:false, value: "num_asset" },
        //{ text: "Etiqueta",sortable:false, value: "tag" },
       // { text: "Observaciones",sortable:false, value: "observations" },
        { text: "Valor de adquisición",sortable:false, value: "purchase_value" },
        { text: "Fecha de compra",sortable:false, value: "purchase_date" },
        // { text: "Vida util",sortable:false, value: "useful_life" },
        // { text: "Costo mant.",sortable:false, value: "maintenance_cost" },
        // { text: "Otros costos",sortable:false, value: "other_cost" },
        // { text: "AF",sortable:false, value: "af" },
        // { text: "Equipo modelo",sortable:false, value: "equipment_model" },
        // { text: "OC",sortable:false, value: "oc" },
        // { text: "Tipo de equipo",sortable:false, value: "equipment_type" },
        // { text: "Monitor modelo",sortable:false, value: "monitor_model" },
        // { text: "Monitor marca",sortable:false, value: "monitor_brand" },
        // { text: "Estado",sortable:false, value: "status", hidden: true }
      ],
      headers2: [
        { text: "Nombre", sortable: false, value: "asset.name" },
        { text: "Tipo", sortable: false, value: "asset.asset_type" },
        { text: "Area", sortable: false, value: "asset.area" },
        { text: "Serie", sortable: false, value: "asset.serial_number" },
        //{ text: "Comentario", sortable: false, value: "asset.area" },
       
        {
          text: "Valor de adquisición",
          sortable: false,
          value: "asset.purchase_value",
        },
        { text: "Estado", sortable: false, value: "messages" }
      ],
    };
  },
  computed: {
    dialogWidth() {
      return this.mode !== "FORM" ? 1200 : 500;
    },
    filteredHeaders() {
      return this.headers.filter(header => header.value !== 'status');
    },
  },
  methods: {
    ...mapActions("asset", ["createAssetMultiple", "downloadAssetFormat"]),
    dialogInput(value) {
      this.$refs.button.$el.blur();
      this.dialog = value;
      this.importMassiveForm = {
        selectedFile: null,
      };
      this.$refs?.form?.resetValidation();
      // if (!value) {
      //   this.$nextTick(() => {
      //     this.classificationForm = {
      //       name: "",
      //       area: null,
      //       responsible_id: null,
      //     };
      //     this.listError = [];
      //     this.$refs?.form?.resetValidation();
      //   });
      // }
    },

    getMessage(success) {
      return success ? { status: "GUARDADO", color: "green" } : { status: "NO GUARDADO", color: "red" }
    },

    async changeFile() {
      this.filelist = [];
      try {
        const file = this.importMassiveForm.selectedFile;

        if (hasExtension(file, [".xlsx"])) {
          const rows = await readXlsxFile(file);
          
          const titles = [
            "Nombre",
            "Número de serie",
            "Tipo de Activo",
            "Área",
            "Nro. Activo",
            "PEP",
            "Etiqueta",

            "Observaciones",
            "Valor de adquisición",
            "Fecha de compra",
            "Vida util",
            "Costo mant.",

            "Otros costos",
            // "AF",
            "Equipo modelo",
            "OC",
            "Tipo de equipo",
            "Monitor modelo",
            "Monitor marca"
          ];

          if (isNotEmpty(rows) && hasTitles(rows, titles)) {
            let hasError = false;

            rows.forEach((row, index) => {
              if (index > 0 && row[0] && row[1] && row[4]) {
            

                if (row[0] ) {


                  this.filelist.push({
                    // name: row[0],
                    // area: row[1],
                    // serial_number: row[2] ? row[2] : null,
                    // asset_type: row[3],
                    // cost: row[4] ? row[4] : null,
                    // // assigned_user_id: row[5] ? row[5] : null,
                    // // comment: hasAsignado ? (row[6] ? row[6] : null) : null

                    // //
                    // num_asset: row[5] ? row[5] : null,
                    // tag:row[6] ? row[6] : null,
                    // // service:row[7] ? row[7] : null,
                    // observations:row[7] ? row[7] : null,
                    // purchase_value:row[8] ? row[8] : null,
                    // useful_life:row[9] ? row[9] : null,
                    // purchase_date:row[10] ? row[10] : null,
                    
                    // maintenance_cost:row[11] ? row[11] : null,
                    // other_cost :row[12] ? row[12] : null,
                    // af:row[13] ? row[13] : null,
                    // equipment_model:row[14] ? row[14] : null,
                    // oc:row[15] ? row[15] : null,
                    
                    name : row[0],
                    serial_number : row[1]?.toString(),
                    asset_type : row[2] ? row[2] : null,
                    area	: row[3],
                    num_asset : row[4] ? row[4]?.toString() : null,
                    pep: row[5]? row[5]:null,
                    tag	: row[6] ? row[6].toString() : null,

                    observations	:row[7] ? row[7].toString() : null,
                    purchase_value :row[8] ? row[8] : null,
                    purchase_date :row[9] ? row[9] : null,
                    useful_life :row[10] ? row[10] : null,
                    maintenance_cost :row[11] ? row[11] : null,
                    
                    other_cost	:row[12] ? row[12] : null,
                    // af  :row[12] ? row[12] : null,
                    equipment_model :row[13] ? row[13] : null,
                    oc  :row[14] ? row[14] : null,
                    equipment_type  :row[15] ? row[15] : null,
                    monitor_model	:row[16] ? row[16] : null,
                    monitor_brand	:row[17] ? row[17] : null,
                    
                  });
                }
              }
            });

            if (!hasError) {
              this.mode = "UPLOAD";
            } else {
              this.mode = "FORM";
            }
          } else {
            this.mode = "FORM";
          }
        }
      } catch (e) {
        $EventBus.$emit(
          "showSnack",
          "error",
          e || "Ocurrio un error al leer el archivo."
        );
        this.mode = "FORM";
      }
    },

    async save() {
      this.loadingSave = true
      const { ok, error, response } = await this.createAssetMultiple(
        //company_id: parseInt(localStorage.getItem("company_id")),
        this.filelist,
      );
      if (ok) {
        // const assets = response;
        // this.savedAssets = Array.isArray(assets) ? assets : [];

        this.savedAssets = this.filelist.map((asset, index) => {
          return {
            ...response[index],
            // asset: response[index].asset || asset,
            asset: asset,
          };
        });
        this.mode = "SAVED";
        await this.$emit('getAssets');
        // $EventBus.$emit(
        // 	'showSnack',
        // 	'success',
        // 	//response?.messages[0] || 'Se ha registrado satisfactoriamente.'
        //   response[0].messages || 'Se ha registrado satisfactoriamente.'
        // );
        this.loadingSave = false;
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(", ") : errorMsm;
        $EventBus.$emit("showSnack", "error", errorMsm);
        this.loadingSave = false;
      }
    },

    formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY');
		},

    async downloadTemplate() {
      try {
        this.loading = true;
        const { ok, error } = await this.downloadAssetFormat();
        if (!ok) {
          console.error(error); // o muestra un mensaje de error usando una alerta/snackbar, por ejemplo.
        }
        this.loading = false;
      } catch (ex) {
        console.error("Hubo un error al descargar el modelo:", ex);
      }
    },

    close() {
      this.dialog = false;
      this.mode = "FORM";
      this.importMassiveForm = {
        selectedFile: null,
      };
      this.savedAssets = [];
      this.$refs?.form?.resetValidation();
    },
  },
};
